import {createContext, FC, ReactNode, useContext} from "react";
import {usePersistentState} from "../util/usePersistentState";

interface BetaContextType {
  enrolledInTimeBeta: boolean
  setEnrolledInTimeBeta: (enrolled: boolean) => void
}
const BetaContext = createContext({} as BetaContextType);

export const BetaContextProvider: FC<{children: ReactNode}> = (props) => {
  const [enrolledInTimeBeta, setEnrolledInTimeBeta] = usePersistentState<boolean>("enrolledInTimeBeta", false)
  return <BetaContext.Provider value={{
    enrolledInTimeBeta,
    setEnrolledInTimeBeta,
  }}>{props.children}</BetaContext.Provider>
}

export const useBetaContext = () => {
  return useContext(BetaContext)
}