import {Dispatch, SetStateAction, useEffect, useState} from "react";

type Serializable = string | number | boolean | null | undefined | Date | Serializable[] | { [key: string]: Serializable };
export const usePersistentState = <T extends Serializable>(key: string, initialValue: T): [T,Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(getInitialValue<T>(key) ?? initialValue)

  useEffect(() => {
    try {
      localStorage?.setItem(`state.${key}`, serialize(state ?? null));
    } catch (e) {
      console.error(e)
      localStorage?.clear()
    }
  }, [state]);

  return [state, setState];
}

function getInitialValue<T extends Serializable>(key: string): T|null {
  try {
    const value = localStorage.getItem(`state.${key}`);
    return value ? deserialize(value) : null;
  } catch (e) {
    console.error(e)
    localStorage?.clear()
    return null;
  }
}

function serialize<T extends Serializable>(value: T): string {
  if (value instanceof Date) {
    return `__Date__${value.toISOString()}`;
  }
  if (value === null) {
    return '__null__';
  }
  if (value === undefined) {
    return '__undefined__';
  }
  return JSON.stringify(value);
}
function deserialize<T extends Serializable>(value: string): T {
  if (value.startsWith('__Date__')) {
    return new Date(value.slice('__Date__'.length)) as unknown as T;
  }
  if (value === '__null__') {
    return null as unknown as T;
  }
  if (value === '__undefined__') {
    return undefined as unknown as T;
  }
  return JSON.parse(value);
}
