import React, {FC, ReactNode} from "react";
import {useBetaContext} from "../BetaContext";
import {InteractiveTimer} from "../time/InteractiveTimer";

export const PageContainer: FC<{children: ReactNode}> = (props) => {
  const {enrolledInTimeBeta} = useBetaContext()
  return <>
    {enrolledInTimeBeta && <InteractiveTimer />}
    {props.children}
  </>
}