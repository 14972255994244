export function formatTimeString(minutes: number): string {
  const wholeHours = Math.floor(minutes / 60);
  const rawMinutes = minutes % 60;
  if (rawMinutes > 0 && rawMinutes % 60 === 0) {
    return `${wholeHours + 1}:00`;
  }
  return `${wholeHours}:${rawMinutes.toString().padStart(2, '0')}`;
}
export function formatSecondsTimeString(seconds: number): string {
  let wholeMinutes = Math.floor(seconds / 60);
  let rawSeconds = Math.floor(seconds % 60);
  if (rawSeconds === 60) {
    rawSeconds = 0;
    wholeMinutes++;
  }
  let wholeHours = Math.floor(wholeMinutes / 60);
  let rawMinutes = Math.floor(wholeMinutes % 60);
  if (rawMinutes === 60) {
    rawMinutes = 0;
    wholeHours++;
  }

  return `${wholeHours}:${rawMinutes.toString().padStart(2, '0')}:${rawSeconds.toString().padStart(2, '0')}`;
}