import {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {faFileSignature} from "@fortawesome/pro-regular-svg-icons";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {Callout} from "../components/content/Callout";
import {SectionHeader} from "../components/content/SectionHeader";
import {Paragraph} from "../components/Paragraph";
import {Button} from "../components/form/Button";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import {useTenant} from "../tenant/TenantContext";
import {useModal} from "../components/layout/ModalProvider";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useRefreshEffect} from "../components/RefreshController";
import {Contract} from "../api/dto";
import {AddContractModal} from "../modals/AddContractModal";
import {DataTable} from "../components/data/DataTable";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {formatTimeString} from "../util/formatTimeString";

export const ArchivedContracts: FC = () => {
  const {getArchivedContracts} = useApiCall()
  const {tenant} = useTenant()
  const {open: openCreateModal} = useModal({title: 'Contract toevoegen', body: <AddContractModal />, size: "lg"})
  const {resource: archivedContracts, reload: reloadArchivedContracts, loading: loadingArchivedContracts} = useFetchedResource(() => getArchivedContracts())

  useRefreshEffect(() => {
    reloadArchivedContracts(undefined)
  })

  return (
    <ContentContainer>
      <PageIconHeader icon={faFileSignature}>
        <Breadcrumbs crumbs={[
          {label: "Servicedesk", href: `/${tenant}/tickets`},
          {label: "Contracten", href: `/${tenant}/contracts`}
        ]} currentPage={"Gearchiveerde contracten"} />
        <PageHeader>Gearchiveerde contracten</PageHeader>
      </PageIconHeader>

      {loadingArchivedContracts ? <>
          Loading archived contracts...
        </> :
        <ContractList contracts={archivedContracts ?? []} reload={() => reloadArchivedContracts(undefined)} />
      }
    </ContentContainer>
  )
}

const ContractList: FC<{ contracts: Contract[]; reload: () => void }> = (props) => {
  const tenant = useTenant()
  const navigate = useNavigate();
  const filteredContracts = props.contracts.sort((a, b) => a.createdAt.getTime() > b.createdAt.getTime() ? -1 : 1)
        return <DataTable
            keyProperty={'id'}
            data={filteredContracts.sort((a, b) => a.name.localeCompare(b.name))} // Sort contracts alphabetically within each group
            columns={[
              {header: 'Klant', property: 'customerId', transform: (_, c) => c.customer.name,},
              {header: 'Contract', property: 'name', transform: (_, c) => (
                  <div>
                    <div className={'font-medium'}>{c.name}</div>
                    <div className={'text-sm opacity-50'}>
                      {moment(c.startAt).format('DD-MM-YYYY')} - {moment(c.endAt).format('DD-MM-YYYY')} (
                      {moment(c.endAt).diff(c.startAt, 'days')} dagen)
                    </div>
                  </div>),},
              {header: 'Categorie', property: 'timeCategoryId', transform: (_, c) => c.timeCategory.name,},
              {header: 'Uren', property: 'hourBudget', transform: (b) => `0 / ${formatTimeString(b)}`,},
            ]}
            placeholder={<>Nog geen contracten</>}
            onClickRow={(contract) => navigate(`/${tenant.tenant}/contracts/${contract.id}`)}
          />
}