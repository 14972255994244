import {FC} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {faFileSignature} from "@fortawesome/pro-regular-svg-icons";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {Callout} from "../components/content/Callout";
import {SectionHeader} from "../components/content/SectionHeader";
import {Paragraph} from "../components/Paragraph";
import {Button, IconButton} from "../components/form/Button";
import {faAdd, faCheck, faTrash, faX} from "@fortawesome/free-solid-svg-icons";
import {useTenant} from "../tenant/TenantContext";
import {useModal} from "../components/layout/ModalProvider";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useRefreshEffect} from "../components/RefreshController";
import {Contract} from "../api/dto";
import {AddContractModal} from "../modals/AddContractModal";
import {SearchPaginateReload} from "../components/data/SearchAndPaginate";
import {DataTable} from "../components/data/DataTable";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import {formatTimeString} from "../util/formatTimeString";
import {usePersistentState} from "../util/usePersistentState";
import {Toggle} from "../components/form/Toggle";
import {ArchivedContracts} from "./ArchivedContracts";
import {faArchive} from "@fortawesome/pro-solid-svg-icons";
import {SectionDivider} from "../components/content/SectionDivider";

export const Contracts: FC = () => {
  const {getContracts} = useApiCall()
  const {tenant} = useTenant()
  const {open: openCreateModal} = useModal({title: 'Contract toevoegen', body: <AddContractModal />, size: "lg"})
  const {resource: contracts, reload: reloadContracts, loading: loadingContracts} = useFetchedResource(() => getContracts())

  useRefreshEffect(() => {
    reloadContracts(undefined)
  })
  console.log({contracts})

  return (
    <ContentContainer>
      <PageIconHeader icon={faFileSignature}>
        <Breadcrumbs crumbs={[
          {label: "Servicedesk", href: `/${tenant}/ticket`},
        ]} currentPage={"Contracten"} />
        <PageHeader>Contracten</PageHeader>
      </PageIconHeader>

      <Callout>
        <SectionHeader>Aan de slag met Klant Contracten</SectionHeader>
        <Paragraph>
          Gebruik contacten om klantafspraken omtrent uren bij te houden voor je klanten.
        </Paragraph>
        <Button size={"sm"} type={"primary"} text={"Contract toevoegen"} icon={faAdd} onClick={() => openCreateModal()} />
      </Callout>

      {loadingContracts ? <>
      Loading contracts...
      </> :
      <ContractList contracts={contracts ?? []} reload={() => reloadContracts(undefined)} />
      }
    </ContentContainer>
  )
}

const ContractList: FC<{ contracts: Contract[]; reload: () => void }> = (props) => {
  const navigate = useNavigate();
  const groupedContracts = props.contracts.reduce((groups, contract) => {
    const statusKey = contract.status; // Keep the status as the key
    if (!groups[statusKey]) {
      groups[statusKey] = [];
    }
    groups[statusKey].push(contract);
    return groups;
  }, {} as Record<string, Contract[]>);
  const statusOrder = ['Actief', 'Concept', 'Afgerond'];
  return <>
    {statusOrder.map((status) => {
      const contractsForStatus = groupedContracts[status];
      if (contractsForStatus && contractsForStatus.length > 0) {
        return <div key={status} className={"my-16"}>
            <h2 className="text-xl font-semibold mb-2">{status}</h2>
            <DataTable
              keyProperty={'id'}
              data={contractsForStatus.sort((a, b) => a.name.localeCompare(b.name))} // Sort contracts alphabetically within each group
              columns={[
                {header: 'Klant', property: 'customerId', transform: (_, c) => c.customer.name,},
                {header: 'Contract', property: 'name', transform: (_, c) => (
                    <div>
                      <div className={'font-medium'}>{c.name}</div>
                      <div className={'text-sm opacity-50'}>
                        {moment(c.startAt).format('DD-MM-YYYY')} - {moment(c.endAt).format('DD-MM-YYYY')} (
                        {moment(c.endAt).diff(c.startAt, 'days')} dagen)
                      </div>
                    </div>),},
                {header: 'Categorie', property: 'timeCategoryId', transform: (_, c) => c.timeCategory.name,},
                {header: 'Uren', property: 'hourBudget', transform: (b) => `0 / ${formatTimeString(b)}`,},
              ]}
              placeholder={<>Nog geen contracten</>}
              onClickRow={(contract) => navigate(`${contract.id}`)}
            />
          </div>
      }
      return null;
    })}

    <div>
      <h1 className={"text-xl font-semibold mb-2"}>Archief</h1>
      <Button type={'secondary'} size={'md'} icon={faArchive} text={'Toon gearchiveerde contracten'} onClick={() => navigate(`archived`)}/>
    </div>
  </>
}