import React, {FC, useState} from "react";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useFetchedResource} from "../api/APIContext";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {Autocomplete, FancyAutocomplete} from "../components/form/Autocomplete";
import {Toggle} from "../components/form/Toggle";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollar, faDollarCircle} from "@fortawesome/pro-light-svg-icons";
import {Contract} from "../api/dto";
import {Select} from "../components/form/Select";

export const EditContractModal: FC<{contract: Contract}> = (props) => {
  const {getCustomers, getTicketTimeCategories, editContract} = useApiCall()
  const {resource: customerResource, reload: reloadCustomers, loading: loadingCustomers} = useFetchedResource(() => getCustomers())
  const {resource: timeCategoriesResource, reload: reloadTimeCategories, loading: loadingTimeCategories} = useFetchedResource(() => getTicketTimeCategories())
  const refactoredCustomers = customerResource?.map((item) => [item.id, item.name])
  const [name, setName] = useState(props.contract.name)
  const [customer, setCustomer] = useState(props.contract.customerId)
  const [timeCategory, setTimeCategory] = useState(props.contract.timeCategoryId)
  const [hourBudget, setHourBudget] = useState<number>(props.contract.hourBudget)
  const [startAt, setStartAt] = useState(new Date(props.contract.startAt))
  const [endAt, setEndAt] = useState(new Date(props.contract.endAt))
  const [isRecurring, setIsRecurring] = useState<boolean>(props.contract.repeatNMonths != null)
  const [repeatMonths, setRepeatMonths] = useState<number>(props.contract.repeatNMonths);
  const [status, setStatus] = useState<string>(props.contract.status)
  const [errors, setErrors] = useState<ErrorBag>({})
  const refresh = useRefresh()

  const statusOptions = {
    'Concept': "Concept",
    'Archief': "Archief",
    'Afgerond': "Afgerond",
    'Actief': "Actief",
  }

  useRefreshEffect(() => {
    reloadCustomers(undefined)
    reloadTimeCategories(undefined)
  })

  const save = async() => {
    if (isRecurring) {
      await editContract(props.contract.id, name, customer, hourBudget, timeCategory, startAt, endAt, status, repeatMonths).catch(error => {
        if (error instanceof ValidationError) {
          setErrors(error.errors)
          throw new Error('Validation error')
        }
      })
      refresh()
    } else {
      await editContract(props.contract.id, name, customer, hourBudget, timeCategory, startAt, endAt, status).catch(error => {
        if (error instanceof ValidationError) {
          setErrors(error.errors)
          throw new Error('Validation error')
        }
      })
      refresh()
    }
  }

  return <>
    <form className={'space-y-4 flex-1'} onSubmit={save}>

      <div className={"flex space-x-3"}>
        <div className={"flex-1"}>
          <Input type={"text"} label={'Naam'} value={name} onChange={(x) => setName(x)}/>
          <InputErrors errors={errors} field={'name'}/>
        </div>
        <div className={"flex-1"}>
          <Select label={'Status'} options={statusOptions} value={status} onChange={setStatus}/>
          <InputErrors errors={errors} field={'status'}/>
        </div>
      </div>

      <div className={'flex space-x-3'}>
        <div className={"flex-1"}>
          <Autocomplete label={"Klant"} options={Object.fromEntries(refactoredCustomers ?? [])} value={customer}
                        onChange={(x) => setCustomer(x)}/>
        </div>
        <div className={"flex-1"}>
          <FancyAutocomplete label={"Tijd categorie"} options={timeCategoriesResource?.map(c => ({
            key: c.id,
            text: c.name,
            subText: c.code,
            leading: <FontAwesomeIcon icon={c.isBillable ? faDollarCircle : faDollar} className={`text-xs ${c.isBillable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/>

          })) ?? []}
                        value={timeCategory} onChange={(x) => setTimeCategory(x)}/>
        </div>
      </div>

      <div>
        <Input type={'time'} label={"Uur budget"} value={hourBudget} onChange={(x) => setHourBudget(x)}/>
        <InputErrors errors={errors} field={'hour_budget'}/>
      </div>

      <div className={"flex space-x-3"}>
        <div className={"basis-1/2"}>
          <Input type={'date'} label={"Start datum"} value={startAt} onChange={setStartAt}/>
        </div>
        <div className={"basis-1/2"}>
          <Input type={'date'} label={"Eind datum"} value={endAt} onChange={setEndAt}/>
        </div>
      </div>
      <InputErrors errors={errors} field={'start_at'}/>
      <InputErrors errors={errors} field={'end_at'}/>

      <div className={"flex space-x-3"}>
        <div className={`flex-1`}>
          <Toggle label={"Vernieuw na afloop?"} value={isRecurring} onChange={setIsRecurring}/>
        </div>
      </div>
      {isRecurring && <div className={"flex items-end space-x-3"}>
        <div className={"basis-1/2"}>
          <Input type={"number"} label={'Herhaal elke ... maanden'} value={repeatMonths}
                 onChange={(x) => setRepeatMonths(x)}/>
          <InputErrors errors={errors} field={'repeat_n_months'}/>
        </div>
        <div className={`basis-1/2 ${!isRecurring ? 'pr-1.5' : ''}`}>
          <h2 className={'font-bold'}>Voorbeeld</h2>
          <div className={'text-sm'}>Bij herhaling loopt het vernieuwde contract van <strong>{moment(startAt).add(repeatMonths, 'months').format('DD-MM-YYYY')}</strong> tot <strong>{moment(endAt).add(repeatMonths, 'months').format('DD-MM-YYYY')}</strong>.</div>
        </div>
      </div>}


    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}