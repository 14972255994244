import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {AuthMiddleware, AuthProvider} from "./auth/AuthProvider";
import {BrowserRouter, Route, Routes, useParams} from "react-router-dom";
import {Test} from "./pages/Test";
import {ModalProvider} from "./components/layout/ModalProvider";
import initSentry from "./sentry";
import {APIContextProvider} from "./api/APIContext";
import {NotFound} from "./pages/NotFound";
import {Tickets} from "./pages/Tickets";
import {RefreshController} from "./components/RefreshController";
import {Dashboard} from "./pages/Dashboard";
import {Relations} from "./pages/Relations";
import {Deals} from "./pages/Deals";
import {MailLists} from "./pages/MailLists";
import {SLA} from "./pages/SLA";
import {Ticketflows} from "./pages/Ticketflows";
import {Assets} from "./pages/Assets";
import {Reports} from "./pages/Reports";
import {Filters} from "./pages/Filters";
import {TicketDetails} from "./pages/TicketDetails";
import {TenantChoice} from "./pages/TenantChoice";
import {TenantContextProvider, useTenant} from "./tenant/TenantContext";
import {PermissionContextProvider} from "./permissions/PermissionContext";
import {Customers} from "./pages/Customers";
import {CustomerDetails} from "./pages/CustomerDetails";
import {TenantSettings} from "./pages/TenantSettings";
import {UserSettings} from "./pages/UserSettings";
import {TeamsDetails} from "./pages/TeamsDetails";
import {Forbidden} from "./pages/Forbidden";
import {NavigationHeader, NavigationItem} from "./components/Navigation";
import {
  faBoxes,
  faBuilding,
  faChartLine, faChartPie,
  faFileContract,
  faFilter,
  faGear,
  faInbox, faShop,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import {RelationDetails} from "./pages/RelationDetails";
import {SlaCreate} from "./pages/SlaCreate";
import {DealDetails} from "./pages/DealDetails";
import {SlaEdit} from "./pages/SlaEdit";
import {MarketPlace} from "./pages/MarketPlace";
import {CampaignMonitor} from "./pages/Integrations/CampaignMonitor";
import {WeFact} from "./pages/Integrations/WeFact";
import {Slack} from "./pages/Integrations/Slack";
import {AuthenticatedLayout} from "./components/layout/AuthenticatedLayout";
import {faFileSignature} from "@fortawesome/pro-regular-svg-icons";
import {Contracts} from "./pages/Contracts";
import {ContractDetails} from "./pages/ContractDetails";
import {ArchivedContracts} from "./pages/ArchivedContracts";
import {BetaContextProvider} from "./components/BetaContext";
import {TimeContextProvider} from "./components/time/TimeContext";
import {PageContainer} from "./components/layout/PageContainer";

initSentry()

const navigationItems = [
  new NavigationItem('Dashboard', faChartLine,  (tenant) => `/${tenant.slug}/dashboard`),
  // Servicedesk
  new NavigationHeader('Servicedesk', {requiredModule: "service_desk"}),
  new NavigationItem('Tickets', faInbox, (tenant) => `/${tenant.slug}/ticket`, {requiredModule: "service_desk"}),
  new NavigationItem('Klanten', faUser, (tenant) => `/${tenant.slug}/customers`, {requiredModule: "service_desk"}),
  new NavigationItem('SLAs', faFileContract, (tenant) => `/${tenant.slug}/sla`, {requiredModule: "service_desk"}),
  new NavigationItem('Contracten', faFileSignature, (tenant) => `/${tenant.slug}/contracts`, {requiredModule: "service_desk"}),
  new NavigationItem('Instellingen', faGear, (tenant) => `/${tenant.slug}/ticketflow`, {requiredModule: "service_desk"}),
  // CRM
  new NavigationHeader('CRM', {requiredModule: "crm"}),
  new NavigationItem('Relaties', faBuilding, (tenant) => `/${tenant.slug}/relations`, {requiredModule: "crm"}),
  new NavigationItem('Deals', faFilter, (tenant) => `/${tenant.slug}/deals`, {requiredModule: "crm"}),
  // Asset management
  new NavigationHeader('Asset Management', {requiredModule: "asset_management"}),
  new NavigationItem('Voorraad', faBoxes, (tenant) => `/${tenant.slug}/assets`, {requiredModule: "asset_management"}),
  new NavigationItem('Rapportages', faChartPie, (tenant) => `/${tenant.slug}/reports`, {requiredModule: "asset_management"}),
  new NavigationItem('Filters', faFilter, (tenant) => `/${tenant.slug}/filters`, {requiredModule: "asset_management"}),
  // Marketplace
  //TODO: Add requiredModule
  new NavigationHeader('Marketplace'),
  new NavigationItem('Integraties', faShop, (tenant) => `/${tenant.slug}/marketplace`),
]

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AuthProvider>
    <BetaContextProvider>
      {/*<React.StrictMode>*/}
        <BrowserRouter>
          <AuthMiddleware>
            <TenantContextProvider>
              <RefreshController>
                <APIContextProvider>
                  <AuthenticatedLayout navigationItems={navigationItems}>
                    <PermissionContextProvider>
                      <ModalProvider>
                        <TimeContextProvider>
                          <RouteProvider />
                        </TimeContextProvider>
                      </ModalProvider>
                    </PermissionContextProvider>
                  </AuthenticatedLayout>
                </APIContextProvider>
              </RefreshController>
            </TenantContextProvider>
          </AuthMiddleware>
        </BrowserRouter>
      {/*</React.StrictMode>*/}
    </BetaContextProvider>
  </AuthProvider>,
)

function RouteProvider() {
  return <Routes>
    <Route path={"/"} element={<OnlyTenants><TenantChoice /></OnlyTenants>}/>
    <Route path={'/user-settings'} element={<UserSettings />}/>
    <Route path={"/forbidden"} element={<Forbidden />}/>
    <Route path={"/:tenant/"} element={<OnlyTenants><Test /></OnlyTenants>}/>

    <Route path={'/:tenant/settings'} element={<OnlyTenants><TenantSettings /></OnlyTenants>}/>

    {/* Dashboard */}
    <Route path={"/:tenant/dashboard"} element={<OnlyTenants><Dashboard /></OnlyTenants>}/>

    {/* Relations */}
    <Route path={"/:tenant/relations"} element={<OnlyTenants><Relations /></OnlyTenants>}/>
    <Route path={"/:tenant/relations/:relation"} element={<OnlyTenants><RelationDetails /></OnlyTenants>}/>

    {/* Deals */}
    <Route path={"/:tenant/deals"} element={<OnlyTenants><Deals /></OnlyTenants>}/>
    <Route path={"/:tenant/deals/:deal"} element={<OnlyTenants><DealDetails /></OnlyTenants>}/>

    {/* Mail lists */}
    <Route path={"/:tenant/mail"} element={<OnlyTenants><MailLists /></OnlyTenants>}/>

    {/* Tickets */}
    <Route path={"/:tenant/ticket"} element={<OnlyTenants><Tickets /></OnlyTenants>}/>
    <Route path={"/:tenant/ticket/:ticket"} element={<OnlyTenants><TicketDetails /></OnlyTenants>}/>

    {/* Contracts */}
    <Route path={"/:tenant/contracts"} element={<OnlyTenants><Contracts /></OnlyTenants>}/>
    <Route path={"/:tenant/contracts/archived"} element={<OnlyTenants><ArchivedContracts /></OnlyTenants>}/>
    <Route path={"/:tenant/contracts/:contract"} element={<OnlyTenants><ContractDetails /></OnlyTenants>}/>

    {/*Customers*/}
    <Route path={"/:tenant/customers"} element={<OnlyTenants><Customers /></OnlyTenants>}/>
    <Route path={"/:tenant/customers/:customer"} element={<OnlyTenants><CustomerDetails /></OnlyTenants>}/>

    {/* SLA */}
    <Route path={"/:tenant/sla"} element={<OnlyTenants><SLA /></OnlyTenants>}/>
    <Route path={"/:tenant/sla/create"} element={<OnlyTenants><SlaCreate /></OnlyTenants>}/>
    <Route path={"/:tenant/sla/:sla/edit"} element={<OnlyTenants><SlaEdit /></OnlyTenants>}/>

    {/* Ticketflows */}
    <Route path={"/:tenant/ticketflow"} element={<OnlyTenants><Ticketflows /></OnlyTenants>}/>

    {/* Assets */}
    <Route path={"/:tenant/assets"} element={<OnlyTenants><Assets /></OnlyTenants>}/>

    {/* Reports */}
    <Route path={"/:tenant/reports"} element={<OnlyTenants><Reports /></OnlyTenants>}/>

    {/* Filters */}
    <Route path={"/:tenant/filters"} element={<OnlyTenants><Filters /></OnlyTenants>}/>

    {/* Teams */}
    <Route path={"/:tenant/teams/:team"} element={<OnlyTenants><TeamsDetails /></OnlyTenants>}/>

    {/* Marketplace */}
    <Route path={"/:tenant/marketplace"} element={<OnlyTenants><MarketPlace /></OnlyTenants>}/>
    {/* Marketplace integrations */}
    <Route path={"/:tenant/marketplace/integrations/campaign_monitor"} element={<OnlyTenants><CampaignMonitor /></OnlyTenants>}/>
    <Route path={"/:tenant/marketplace/integrations/wefact"} element={<OnlyTenants><WeFact /></OnlyTenants>}/>
    <Route path={"/:tenant/marketplace/integrations/slack"} element={<OnlyTenants><Slack /></OnlyTenants>}/>

    {/* Error handling */}
    <Route path={"*"} element={<NotFound/>}/>
  </Routes>
}
function OnlyTenants({children}: {children: JSX.Element}): JSX.Element {
  const {tenant} = useParams()
  const {tenantChanged, tenant: tenantInContext} = useTenant()
  useEffect(() => {
    if (tenant) {
      tenantChanged(tenant)
    }
  }, [tenant]);
  if (tenantInContext === null) {
    return <TenantChoice />
  }
  return <PageContainer>{children}</PageContainer>
}
//
// function tenantExists(tenants: Tenant[], tenantSlug: string | undefined): boolean {
//   if (!tenantSlug) {
//     return false;
//   }
//   return tenants.some((tenant) => tenant.slug === tenantSlug);
// }
