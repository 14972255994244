import {createContext, FC, ReactNode, useContext} from "react";
import {usePersistentState} from "../../util/usePersistentState";
import {useTenant} from "../../tenant/TenantContext";

interface TimeContextType {
  startCategoryTimer: (ticketId: string, ticketSubject: string, startAt: Date, description: string, categoryId: string, categoryName: string, billable: boolean) => void
  startContractTimer: (ticketId: string, ticketSubject: string, startAt: Date, description: string, contractId: string, contractName: string, billable: boolean) => void
  runningTimer: SavedTimer | null
  clearTimer: () => void
  updateTimer: (timer: Partial<Pick<SavedTimer,'description'|'startAt'>>) => void
}
const TimeContext = createContext({} as TimeContextType);

type SavedTimer = {
  ticketId: string,
  ticketSubject: string,
  description: string,
  startAt: Date|string,
} & (
  {type: 'category', categoryId: string, categoryName: string, billable: boolean} |
  {type: 'contract', contractId: string, contractName: string, billable: boolean}
)

export const TimeContextProvider: FC<{children: ReactNode}> = (props) => {
  const {tenant} = useTenant()
  const [savedTimer, setSavedTimer] = usePersistentState<{[tenant:string]: SavedTimer }>(`runningTimers`, {})
  return <TimeContext.Provider value={{
    startCategoryTimer: (ticketId, ticketSubject, startAt, description, categoryId, categoryName, billable) => {
      setSavedTimer(old => {
        return {...old, [tenant??'_']: {ticketId, ticketSubject, startAt, description, type: 'category', categoryId, categoryName, billable}}
      })
    },
    startContractTimer: (ticketId, ticketSubject, startAt, description, contractId, contractName, billable) => {
      setSavedTimer(old => {
        return {...old, [tenant??'_']: {ticketId, ticketSubject, startAt, description, type: 'contract', contractId, contractName, billable}}
      })
    },
    runningTimer: savedTimer[tenant??'_'],
    clearTimer: () => {
      setSavedTimer(old => {
        const {[tenant??'_']: _, ...rest} = old
        return rest
      })
    },
    updateTimer: (timer) => {
      setSavedTimer(old => {
        const oldTimer: SavedTimer|undefined = old[tenant ?? '_']
        if (!oldTimer) return old
        return {...old, [tenant ?? '_']: {...oldTimer, ...timer}}
      })
    },
  }}>{props.children}</TimeContext.Provider>
}

export const useTimeContext = () => {
  return useContext(TimeContext)
}