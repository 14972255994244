import React, {FC, useState} from "react";
import {useTimeContext} from "./TimeContext";
import {useApiCall, ValidationError} from "../../api/api";
import {useInterval} from "../../util/useInterval";
import {formatSecondsTimeString} from "../../util/formatTimeString";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollar, faDollarCircle, faFile} from "@fortawesome/pro-light-svg-icons";
import {IconAvatar} from "../content/IconAvatar";
import {faCancel, faStopwatch} from "@fortawesome/pro-regular-svg-icons";
import {Input} from "../form/Input";
import {Button, IconButton} from "../form/Button";
import {NavLink} from "react-router-dom";
import {useTenant} from "../../tenant/TenantContext";
import {useRefresh} from "../RefreshController";

export const InteractiveTimer: FC = () => {
  const {runningTimer, updateTimer, clearTimer} = useTimeContext()
  const {tenant} = useTenant()
  const {addTicketTimeWithCategory, addTicketTimeWithContract} = useApiCall()
  const [time, setTime] = useState<number>(0)
  const refresh = useRefresh()

  useInterval(() => {
    if (runningTimer === null || runningTimer === undefined) return
    const start = runningTimer.startAt instanceof Date ? runningTimer.startAt : new Date(runningTimer.startAt)
    setTime((new Date().getTime() - start.getTime()) / 1000)
  }, 1000)

  if (runningTimer === null || runningTimer === undefined) {
    return <></>
  }


  const save = async () => {
    const start = runningTimer.startAt instanceof Date ? runningTimer.startAt : new Date(runningTimer.startAt)
    if (runningTimer.type === 'category') {
      await addTicketTimeWithCategory(runningTimer.ticketId, start, Math.round(time/60), runningTimer.description, runningTimer.categoryId).catch(error => {
        if (error instanceof ValidationError) {
          throw new Error('Validation error')
        }
        throw error
      })
    } else {
      await addTicketTimeWithContract(runningTimer.ticketId, start, Math.round(time/60), runningTimer.description, runningTimer.contractId).catch(error => {
        if (error instanceof ValidationError) {
          throw new Error('Validation error')
        }
        throw error
      })
    }
    clearTimer()
    refresh()
  }

  return <div className={'-mx-8 -mt-12 mb-12 border-b px-8 py-6 border-slate-200 dark:border-zinc-600 grid grid-cols-3 gap-x-4'}>
    {/* About ticket */}
    <div className={'flex items-center'}>
      <IconAvatar icon={faStopwatch} />
      <div className={'ml-4'}>
        <div className={'font-bold'}>{runningTimer.ticketSubject}</div>
        <div className={'flex items-center space-x-2'}>
          {runningTimer.type === 'contract' ? <>
            <FontAwesomeIcon icon={faFile}
                             className={`text-xs ${runningTimer.billable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/>
            <div>{runningTimer.contractName}</div>
          </> : <>
            <FontAwesomeIcon icon={runningTimer.billable ? faDollarCircle : faDollar} className={`text-xs ${runningTimer.billable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/>
            <div>{runningTimer.categoryName}</div>
          </>}
          <div className={'pl-2 mt-1 text-sm underline'}>
            <NavLink to={`/${tenant}/ticket/${runningTimer.ticketId}`} className={'text-brand-500 dark:text-brand-500'}>Naar ticket</NavLink>
          </div>
        </div>
      </div>
    </div>
    {/* Description */}
    <div className={'flex flex-col items-stretch'}>
      <Input type={'text'} label={''} value={runningTimer.description} onChange={(d) => updateTimer({description: d})}/>
    </div>
    {/* Controls */}
    <div className={'flex items-center justify-end space-x-4'}>
      <div className={'mx-4 text-2xl font-bold'}>{formatSecondsTimeString(time)}</div>
      <IconButton type={'danger'} icon={faCancel} size={'md'} onClick={() => clearTimer()} />
      <Button type={'primary'} icon={faStopwatch} size={'md'} onClick={save} disabled={runningTimer.description.trim().length === 0} text={'Opslaan'} />
    </div>
  </div>
}